import { PARTNERTYPES } from '@config';
import { UserService } from '@features/auth';
import { CPQ_ROLES } from '../roles';
import { FilterableMenuItem } from './model';
import { cpqSalesConsultant } from './shared';

export const settingsOfferDeliveryAndPaymentMenuItem = {
  title: 'settings.offer.delivery_and_payment',
  routerLink: '/settings/offer/custom-page',
  icon: 'subject',
};

export const settingsOfferPaymentTermsMenuItem = {
  title: 'settings.offer.payment_terms.page_name',
  routerLink: '/settings/offer/payment-terms',
  icon: 'payments',
};

export const settingsOfferMenuItem = {
  title: 'settings.offer.page_name',
  routerLink: '/settings/offer',
  icon: 'local_offer',
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_SETTINGS),
  children: [
    settingsOfferDeliveryAndPaymentMenuItem,
    settingsOfferPaymentTermsMenuItem,
  ],
};

export const settingsCustomArticleCategoryMenuItem = {
  title: 'project.categories',
  routerLink: '/settings/custom-articles/categories',
  icon: 'category',
};

export const settingsCustomArticlePricelistsMenuItem = {
  title: 'settings.custom_articles.pricelists',
  routerLink: '/settings/custom-articles/pricelists',
  icon: 'list',
};

export const settingsCustomArticleMenuItem = {
  title: 'settings.custom_articles.page_name',
  routerLink: '/settings/custom-articles',
  icon: 'note_add',
  children: [
    settingsCustomArticlePricelistsMenuItem,
    settingsCustomArticleCategoryMenuItem,
  ],
};

export const settingsSalesProjectMenuItem = {
  title: 'settings.sales_project.page_name',
  routerLink: '/settings/sales-project',
  icon: 'attach_money',
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_SETTINGS),
};

export const settingsShippingCostsMenuItem = {
  title: 'settings.shipping_costs.page_name',
  routerLink: '/settings/shipping-costs',
  icon: 'local_shipping',
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_SETTINGS) &&
    userService.userContext.partnertype === PARTNERTYPES.GENERAL_AGENT,
};

export const settingsServicesMenuItem = {
  title: 'settings.services.page_name',
  routerLink: '/settings/services',
  icon: 'home_repair_service',
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_SETTINGS) &&
    userService.userContext.partnertype === PARTNERTYPES.GENERAL_AGENT,
};

export const settingsBestInvestGlobalSettingsMenuItem = {
  title: 'settings.best_invest.global_settings',
  routerLink: '/settings/best-invest/global-settings',
  icon: 'settings',
};

export const settingsBestInvestMenuItem = {
  title: 'settings.best_invest.page_name',
  icon: ' timeline',
  routerLink: '/settings/best-invest',
  filterPredicate: (userService: UserService) =>
    userService.hasRole(CPQ_ROLES.CPQ_SETTINGS) &&
    userService.userContext.partnertype === PARTNERTYPES.GENERAL_AGENT,
  children: [settingsBestInvestGlobalSettingsMenuItem],
};

export const settingsMenu: FilterableMenuItem = {
  title: 'common.settings',
  icon: 'settings',
  routerLink: '/settings',
  filterPredicate: (userService: UserService) =>
    !cpqSalesConsultant(userService) && userService.hasRole(CPQ_ROLES.CPQ_USER),
  children: [
    settingsBestInvestMenuItem,
    settingsCustomArticleMenuItem,
    settingsOfferMenuItem,
    settingsSalesProjectMenuItem,
    settingsShippingCostsMenuItem,
    settingsServicesMenuItem,
  ],
};
