import { configurationMenu } from './configuration-menu';
import { FilterableMenu } from './model';

const isLoaderCrane = (productLine: string): boolean => productLine === 'B1';

export const bestInvestSelectionItem = {
  data: { id: 'best-invest' },
  title: 'benefits.best_invest.best_invest_savings',
  routerLink: '/best-invest-savings',
  icon: 'trending_up',
  filterPredicate: (productLine: string) => isLoaderCrane(productLine),
};

export const solutionMenu: FilterableMenu = {
  title: '', // dynamic created
  navigation: [
    configurationMenu,
    bestInvestSelectionItem,
    {
      title: 'common.calculation',
      icon: 'calculate',
      routerLink: '/calculation',
    },
    {
      title: 'offer.offer',
      icon: 'local_offer',
      routerLink: '/offer',
    },
    {
      title: 'checkout.checkout',
      icon: 'send',
      routerLink: '/checkout',
    },
  ],
};
