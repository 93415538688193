import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APPS, PARTNERTYPES } from '@config';

import { Location } from '@angular/common';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { UserService } from '@features/auth';
import { RootSelectors } from '@features/root-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Badges, OrderService } from '@sales-libs/order/data-access';
import { Cart } from '@sales-libs/project/data-access';
import { CartSelectors, ProjectSelectors } from '@sales-libs/project/feature';
import {
  FEATURE_FLAG_BEST_INVEST,
  FEATURE_FLAG_EARLY_ACCESS,
  FEATURE_FLAG_REPORTING,
  FEATURE_FLAG_SERVICE_CONTRACTS,
  FEATURE_FLAG_SOLUTIONS_INTEGRATION,
  SlSharedProductLineToEquipmentPipe,
  SnackbarActions,
  UserActions,
} from '@sales-libs/shared/util';
import { GaService } from '@shared-lib/google-analytics';
import { Observable, combineLatest, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
} from 'rxjs/operators';
import { appMenu, solutionMenu } from './shared/menu';
import { FilterableMenuItem } from './shared/menu/model';

@Component({
  selector: 'cpq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('routerContainer') routerContainer: ElementRef;

  totalAmount$: Observable<number>;

  offerPage: boolean;

  readonly showLoadingSpinner$: Observable<boolean>;
  readonly loadingSpinnerValue$: Observable<number>;
  menu: Menu;
  cartMenu?: Menu;

  get isUserGeneralAgent(): boolean {
    return (
      this.userService?.userContext?.partnertype === PARTNERTYPES.GENERAL_AGENT
    );
  }

  private _productLineToEqupimentPipe =
    new SlSharedProductLineToEquipmentPipe();
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    public userService: UserService,
    private _ordersService: OrderService,
    private _translateService: TranslateService,
    private _store: Store,
    private _gaService: GaService,
    private _router: Router,
    private _acRoute: ActivatedRoute,
    private location: Location,
    @Inject(FEATURE_FLAG_EARLY_ACCESS)
    private _isEarlyAccessFeatureFlagSet,
    @Inject(FEATURE_FLAG_SERVICE_CONTRACTS)
    public isServiceContractFeatureFlagSet,
    @Inject(FEATURE_FLAG_SOLUTIONS_INTEGRATION)
    public isSolutionIntegrationFeatureFlagSet,
    @Inject(FEATURE_FLAG_REPORTING)
    private _isReportingFeatureFlagSet,
    @Inject(FEATURE_FLAG_BEST_INVEST)
    private _isBestInvestFeatureFlagSet,
  ) {
    this._matIconRegistry.addSvgIcon(
      `calculator`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/icons/calculator.svg',
      ),
    );
    this._gaService.create();

    // show/hide loadingSpinner
    this.showLoadingSpinner$ = this._store.select(
      RootSelectors.showLoadingSpinner,
    );

    this.loadingSpinnerValue$ = this._store
      .select(RootSelectors.loadingSpinnerValue)
      .pipe(map((value) => value || 0));

    // router reset
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.routerContainer) {
          this.routerContainer.nativeElement.scrollTop = 0;
        }
      });

    // setup dynamic menu
    combineLatest([
      this._store
        .select(ProjectSelectors.currentProject)
        .pipe(distinctUntilChanged((x, y) => x?.id === y?.id)),
      this._store
        .select(CartSelectors.current)
        .pipe(distinctUntilChanged((x, y) => x?.id === y?.id)),
      this._store
        .select(CartSelectors.mainProduct)
        .pipe(distinctUntilChanged((x, y) => x?.id === y?.id)),
    ]).subscribe(([project, cart, product]) => {
      if (project && cart && cart.project_id === project.id) {
        const baseLink = '/projects/' + project.id + '/carts/' + cart.id;
        this.cartMenu = {
          ...solutionMenu,
          title: project.name + ' (' + cart.version + ')',
          equipmentIcon: this._productLineToEqupimentPipe.transform(
            cart?.product_line || 'A1',
          ),
          backButtonUrl: '/projects/' + project.id,
          navigation:
            solutionMenu.navigation
              ?.filter(
                (child) =>
                  !child.filterPredicate ||
                  (child.data?.id === 'best-invest' &&
                    child.filterPredicate(cart.product_line) &&
                    this._isBestInvestFeatureFlagSet),
              )
              .map((child) =>
                this._mapSolutionNavItems(child, baseLink, cart, product?.name),
              ) || [],
        };
      } else {
        this.cartMenu = undefined;
      }
    });
  }

  ngOnInit() {
    const currentPath = this.location.path();
    // Check if the URL matches the pattern "/offer/{number}-{number}" and it's in the correct order
    this.offerPage = /\/offer\/\d+-\d+$/.test(currentPath);

    this.userService.isAuthorized$
      .pipe(
        filter((isAuthorized) => isAuthorized),
        take(1),
      )
      .subscribe(() => {
        if (this.userService.hasRole(APPS.PALDESK_CPQ.ROLES.CPQ_USER)) {
          this._store.dispatch(UserActions.GetEarlyAccessRequested());
        }
        this._setupMenu();
      });
  }

  private _mapSolutionNavItems(
    item: FilterableMenuItem,
    baseLink: string,
    cart: Cart,
    productName?: string | null,
  ) {
    return {
      ...item,
      routerLink: baseLink + item.routerLink,
      children: item.children
        ?.map((child) =>
          this._mapSolutionNavItems(child, baseLink, cart, productName),
        )
        // filter out sub items of configuration if cart is locked
        .filter(() => !cart.locked || item.data?.id !== 'configuration')
        .filter(
          (child) =>
            !child.filterPredicate ||
            (item.data?.id === 'configuration' &&
              child.filterPredicate(!!productName)),
        )
        .map((child) =>
          child.data?.id === 'configuration-product'
            ? { ...child, title: productName }
            : child,
        ),
    };
  }

  private _setupMenu() {
    if (this.userService.hasRole(APPS.PALDESK_CPQ.ROLES.CPQ_USER)) {
      this._ordersService
        .getBadges()
        .pipe(
          catchError((err) => {
            this._store.dispatch(
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.order.badges',
                ),
              }),
            );
            return of(null);
          }),
        )
        .pipe(startWith(null))
        .subscribe((badges) => {
          this._createMenu(badges);
        });
    } else {
      this._createMenu(null);
    }
  }

  private _createMenu(badges: Badges | null) {
    this.menu = {
      ...appMenu,
      navigation: appMenu.navigation
        .filter(
          (item) =>
            !item.filterPredicate || item.filterPredicate(this.userService),
        )
        .filter(
          (item) =>
            item.data?.id !== 'earlyAccess' ||
            this._isEarlyAccessFeatureFlagSet,
        )
        .filter(
          (item) =>
            item.data?.id !== 'reporting' || this._isReportingFeatureFlagSet,
        )
        .map((item) => ({
          ...item,
          ...(item.data?.id === 'order' &&
          badges &&
          badges.new_current_orders + badges.new_current_orders > 0 &&
          !this.userService.hasRole(
            APPS.PALDESK_CPQ.ROLES.CPQ_SALES_REPRESENTATIVE,
          )
            ? { badge: '!', isBadgeIcon: true }
            : {}),
          children: this._getChildren(item.children, badges),
        })),
    };
  }

  private _getChildren(
    children: FilterableMenuItem[] | undefined,
    badges: Badges | null,
  ): FilterableMenuItem[] | undefined {
    if (!children) return undefined;
    return children
      .filter(
        (child) =>
          (!child.filterPredicate || child.filterPredicate(this.userService)) &&
          (child.data?.id !== 'inquiries' ||
            this.isSolutionIntegrationFeatureFlagSet),
      )
      .map((childItem) => ({
        ...childItem,
        children: childItem.children
          ? childItem.children.filter(
              (child) =>
                !child.filterPredicate ||
                child.filterPredicate(this.userService),
            )
          : childItem.children,
      }))
      .map((childItem) =>
        this.userService.hasRole(
          APPS.PALDESK_CPQ.ROLES.CPQ_SALES_REPRESENTATIVE,
        )
          ? childItem
          : this._addBadgesToOrderItems(childItem, badges),
      );
  }

  private _addBadgesToOrderItems(
    item: FilterableMenuItem,
    badges: Badges | null,
  ): FilterableMenuItem {
    switch (item.data?.id) {
      case 'currentOrders': {
        return {
          ...item,
          badge: badges?.new_current_orders || undefined,
        };
      }
      case 'ordersFailedToSend': {
        return {
          ...item,
          badge: badges?.failed_to_send_orders || undefined,
        };
      }
      default: {
        return item;
      }
    }
  }
}
